import { post } from "@/utils/request";

/**
 * 获取最新安卓版本信息
 * @returns Object
 */
export const getLastAndroidInfo = (data) =>
  post("/kuke_app/android_version_by_h5", data);

/**
 * 停服弹框信息获取
 */
export const getBulletboxInfo = (data) =>
  post("/book/bulletbox/manage/getBulletboxInfo", data);
