<template>
  <div class="stopShadow" v-cloak v-if="type === 1">
    <div class="heights">
      <img src="@/assets/stop-star.png" />
    </div>
    <div class="stopShadow-bg">
      <img src="@/assets/stop-bg.png" class="stop-bg" />
      <div class="stop-content">
        <div class="stop-top">
          <img src="@/assets/stop-top.png" />
        </div>
        <h3>停服升级公告</h3>
        <div class="stop-inner">
          <div v-html="BulletboxInfo"></div>
          <div class="stop-end">特此公告</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBulletboxInfo } from "@/api/app";
export default {
  name: "stop-shadow",
  data() {
    return {
      BulletboxInfo: "",
      type: "",
    };
  },
  created() {
    this.getDate();
  },
  methods: {
    async getDate() {
      const { BulletboxInfo, type } = await getBulletboxInfo();
      this.BulletboxInfo = BulletboxInfo;
      this.type = type;
    },
  },
};
</script>

<style>
.stopShadow {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.stopShadow-bg {
  width: 600px;
  margin: 0 auto;
  position: relative;
  background: #fff;
  border-radius: 30px;
  min-height: 746px;
}

.stop-content {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #333;
  position: relative;
  z-index: 2;
  padding-bottom: 34px;
}

.heights {
  height: 21%;
  width: 600px;
  margin: 0 auto;
  position: relative;
}
.heights img {
  width: 100%;
  position: absolute;
  bottom: -160px;
  left: 0;
}
.stop-top {
  text-align: center;
  top: -15%;
}

.stop-top img {
  width: 202px;
  margin-top: -15%;
}

.stop-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.stop-content h3 {
  text-align: center;
  font-size: 36px;
  margin: 2% 0;
}
.stop-inner {
  line-height: 50px;
  font-size: 28px;
  padding: 0 48px;
  text-align: left;
}
.stop-inner span {
  display: inline-block;
  width: 10.5%;
}
.stop-inner p {
  text-align: right;
}
.stop-end {
  text-align: right;
  font-weight: bold;
  margin-top: 34px;
}
</style>
