<template>
  <div>
    <div class="bg-container">
      <div>
        <img class="kukeIcon" src="@/assets/imgs/app/logo.png" alt="" />
      </div>
      <div class="kukeMiniProgram">库课图书小程序</div>
      <div class="upgradeKuke">若无法立即打开，请升级库课网校app</div>

      <div class="openApp" @click="openApp">立即打开</div>
      <div class="openApp" style="margin-top: 21px" @click="downLoadApp">
        立即下载
      </div>
    </div>
    <van-overlay :show="isWeixin">
      <div class="overlay" @click.stop>
        <div class="desc">点击右上角选 择浏览器打开</div>
      </div>
    </van-overlay>
    <shadow-stop></shadow-stop>
  </div>
</template>

<script>
import { getClient, isWeixin } from "@/utils/utils";
import { getLastAndroidInfo } from "@/api/app/index";
const IOS_SCHEMA = "kuke://openApp?openMiniProgram=4";
const ANDROID_SCHEMA = "kk://androidh5/openapp?openMiniProgram=4";
const IOS_DOWNLOAD =
  "itms-apps://itunes.apple.com/cn/app/jie-zou-da-shi/id1110639107?mt=8";
import shadowStop from "@/components/shadowStop";
export default {
  components: {
    shadowStop,
  },
  data() {
    return {
      client: "",
      isWeixin: false,
      downloadUrl:
        "itms-apps://itunes.apple.com/cn/app/jie-zou-da-shi/id1110639107?mt=8",
      ANDROID_DOWNLOAD: "",
    };
  },
  mounted() {
    this.client = getClient();
    this.isWeixin = isWeixin();
    this.getLastAndroidInfo();
  },
  methods: {
    async getLastAndroidInfo() {
      const params = {
        order_number: "1",
      };
      const { code, data } = await getLastAndroidInfo(params);
      if (code != "0") return;
      this.ANDROID_DOWNLOAD = data.url;
    },
    downLoadApp() {
      this.initUrl();
      var a = document.createElement("a");
      a.setAttribute("href", this.downloadUrl);
      a.setAttribute("download", this.downloadUrl);
      document.body.appendChild(a);
      a.click();
    },
    initUrl() {
      this.downloadUrl =
        this.client == "IOS" ? IOS_DOWNLOAD : this.ANDROID_DOWNLOAD;
    },

    /// 立即打开APP
    openApp() {
      if (this.client === "Android") {
        var a = document.createElement("a");
        a.setAttribute("href", ANDROID_SCHEMA);
        document.body.appendChild(a);
        a.click();
      } else if (this.client === "IOS") {
        //ios的scheme协议
        var b = document.createElement("a");
        b.setAttribute("href", IOS_SCHEMA);
        b.setAttribute("target", "_blank");
        document.body.appendChild(b);
        b.click();
      }
    },
  },
};
</script>

<style scoped>
.bg-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 185px;
}

.kukeIcon {
  width: 188px;
}

.kukeMiniProgram {
  font-size: 34px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin: 30px 0 53px;
}
.upgradeKuke {
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 40px;
  margin-bottom: 121px;
}

.download {
  font-size: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f01414;
  line-height: 40px;
  margin: 42px 0 137px;
}

.openApp {
  width: 520px;
  padding: 30px;
  box-sizing: border-box;

  background: linear-gradient(90deg, #ff5151 0%, #f61313 100%);
  border-radius: 54px;
  font-size: 36px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.download {
  margin-top: 42px;
}
.overlay {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 5px 5px 0 0;
}
.more {
  width: 92px;
  height: 60px;
}

.desc {
  padding: 100px 28px 0 28px;
  box-sizing: border-box;
  width: 230px;
  height: 202px;
  background: url("../../assets/imgs/app/desc.png");
  background-size: 100% auto;
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
</style>
